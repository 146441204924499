.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navText{ color: white ; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1000px) {
  .w90{
    overflow-y: auto;
    width: 100vw;
    max-width: 100vw !important;
  }
}

@media only screen and (min-width: 1000px) {
  .w90{
    overflow-y: auto;
    width: 90vw;
    max-width: 90vw !important;
  }
}

@media only screen and (min-width: 1400px) {
  .w90{
    overflow-y: auto;
    width: 80vw;
    max-width: 80vw !important;
  }
}

.border-modal{
  border-bottom: 2px #282c34 solid;
}

.border-modal-both{
  border-bottom: 2px #282c34 solid;
  border-top: 2px #282c34 solid;
}
.upDown:hover{
  transform: scale(1.5);


}
.btn{
  border: none !important; 
  box-shadow: none!important; 
}
.btn:active{
  border: none !important; 
  box-shadow: none!important; 
}

.dropdown-item{
  color: #ffffff !important;
  text-decoration: none !important;
}

.dropdown-item a{
  color: #ffffff !important;
  text-decoration: none !important;
}

.dropdown-item:hover{
  color: #343a40 !important;
  text-decoration: none !important;
}

.dropdown-item:hover a{
  color: #343a40 !important;
  text-decoration: none !important;
}

.dropdown-menu{
  background-color: #343a40 !important;
}

.login-form{
  width: 300px !important;
}

.login-form-error {
  color: red;
}

.nav-text{
  color: #fff !important;
  padding: 6px 12px !important;
}

